.download {
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.3);
    width: 100vw;
    height: 100vh;
    z-index: 20;
}

.download-background {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    width: 70vw;
    height: 200px;
    background-color: rgba(68, 68, 68, 0.9);
    top: 50%;
    left: 50%;
    margin-left: -35vw;
    margin-top: -100px;
    color: var(--white);
    border-radius: 10px;
}

.free-download {
    text-decoration: underline;
}

.fa-times {
    font-size: 2rem;
    position: absolute;
    top: 0.2rem;
    right: 0.4rem;
    cursor: pointer;
    color: gray;
    transition: all 0.2s;
}

.fa-times:hover {
    color: var(--white);
}

.download p {
    font-weight: 500;
}

.download-btn {
    display: flex;
    justify-content: center;
}

.download-btn a {
    color: inherit;
    text-decoration: none;
}

.download-option {
    display: flex;
    align-items: center;
    margin: 0 1rem;
    cursor: pointer;
    transition: 0.2s all;
}

.download-option:hover {
    color: var(--accent);
    transform: scale(1.05);
}

.download-option h3 {
    font-weight: 500;
}

.download-option p {
    margin-left: 1rem;
    color: var(--accent);
    font-weight: 500;
    text-shadow: 0 0 5px black;
}

.download-option .fa-download {
    position: static;
    color: inherit;
    margin-right: 0.8rem;
}

.download-option .fa-download:hover {
    transform: none;
}