.musicPlayer {
    position: absolute;
    top: 0;
    left: 0;
}

.progress-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 2.5px;
    background-color: var(--light);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
    transition: height 0.1s ease-out;
    cursor: pointer;
    z-index: 10;
}

.progress-background:hover {
    height: 16px;
}

.progress {
    position: absolute;
    height: inherit;
    background-color: var(--accent);
    box-shadow: 1px 0px 2px rgba(146, 25, 126, 0.7);
}

.progress-hoverarea {
    position: absolute;
    width: 100vw;
    height: 21px;
}

.time-display {
    position: fixed;
    top: 0.2rem;
    right: 0.5rem;
    background-color: var(--dark);
    padding: 0 0.2rem;
    border-radius: 3px;
    z-index: 10;
}

.controls {
    position: fixed;
    top: 2.5rem;
    right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 10;
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
    padding: 1rem 1rem 2rem 1rem;
}

.marquee {
    overflow: hidden;
    max-width: 200px;
    border-radius: 2px;
    background-color: var(--light);
}

.marquee-p {
    color: var(--dark);
    padding: 2px 10px 3px;
    animation: marquee 9s linear infinite;
    white-space: nowrap;
    width: max-content;
    animation-play-state: paused;
}

.scrolling {
    animation-play-state: running;
}

@keyframes marquee {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(-100%);
    }
}

.controls i {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 2rem;
    margin: 0 0.3rem;
    background-color: var(--dark);
    border-radius: 3px;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.4);
}

.controls i:hover {
    color: var(--accent);
    background-color: #4d4d4d;
}

.controls i:active {
    color: var(--dark);
    background-color: var(--accent);
}

.title-genre {
    display: none;
}