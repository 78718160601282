.contact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 2rem;
}

.contact h3 {
    font-size: 2rem;
    margin-bottom: 2rem;
    padding: 0.4rem 0.8rem;
    color: var(--accent);
}