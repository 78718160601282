.home {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    margin: 4rem;
    min-height: 20rem;
}

.home-text {
    margin-bottom: 1.2rem;
}

.home-text a {
    text-decoration: none;
    color: var(--accent);
    border: 1px solid var(--accent);
    border-radius: 30px;
    padding: 0.5rem 1rem;
    transition: all 0.2s;
}

.home-text a:hover {
    color: var(--white);
    background-color: var(--accent);
    opacity: 0.8;
}

.new-arrival {
    margin-top: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.new-arrival p {
    font-weight: 300;
}

.new-arrival .new {
    color: var(--accent);
    animation: new-animation 3s infinite;
}

.new-arrival .cards {
    display: flex;
    justify-content: center;
    align-items: center;
}

@keyframes new-animation {
    0% {
        opacity: 1;
    }

    25% {
        opacity: 0;
    }

    50% {
        opacity: 0;
    }

    75% {
        opacity: 1;
    }

    100% {
        opacity: 1;
    }
}

.new-arrival-link {
    text-decoration: none;
    color: var(--dark);
    text-align: center;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    padding: 0.2rem 3.3rem;
    border-radius: 30px;
    transition: all 0.2s;
    background-color: var(--light);
}

.new-arrival-link:hover {
    color: var(--accent);
    transform: scale(1.04);
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.new-arrival-link:hover .fa-angle-double-down {
    color: var(--accent);
    transform: scaleX(3);
}

.new-arrival .fa-angle-double-down {
    color: var(--dark);
    margin-top: 0.1rem;
    transform: scaleX(2);
    transition: all 0.2s;
}

.genre-buttons {
    margin-top: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    max-width: 50rem;
}

.genre-buttons h3 {
    margin: 1.5rem;
}

.genre-buttons h3 a {
    background-color: var(--dark);
    padding: 0.7rem 1.4rem;
    text-decoration: none;
    color: var(--white);
    border-radius: 30px;
    transition: all 0.2s;
}

.genre-buttons h3 a:hover {
    background-color: var(--light);
    padding: 0.7rem 1.4rem;
    text-decoration: none;
    color: var(--accent);
    border-radius: 30px;
}