.Nav {
    background-color: var(--dark);
    color: var(--white);
}

/* .top-border {
    width: 100%;
    height: 2.5px;
    background-color: var(--light);
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.7);
  } */

.Nav .logo-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.Nav .logo-title a {
    margin-left: 1rem;
    width: 3.8rem;
    height: 3.8rem;
    align-self: flex-end;
}

.Nav .logo {
    width: 3.8rem;
    height: 3.8rem;
    opacity: 0.8;
}

.Nav h1 {
    display: inline-block;
    font-weight: 100;
    font-size: 1.6rem;
    padding: 1.5rem 1rem 1rem 0;
}

.Nav ul {
    display: flex;
    justify-content: flex-start;
}

li {
    list-style: none;
    margin: 1rem 2rem;
}

.Nav a {
    text-decoration: none;
    color: var(--white);
    transition: all 0.2s;
}

.Nav a:hover {
    color: var(--accent);
}

.Nav h1 a:hover {
    color: var(--white);
}