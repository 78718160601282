.volume-fill {
    position: absolute;
    height: 17px;
    background-color: var(--accent);
    border-radius: 1px;
}

.volume-background {
    width: 100%;
    height: 17px;
    background-color: white;
    border-radius: 1px;
    box-shadow: 2px 2px 1px rgba(0, 0, 0, 0.4);
}

.volume-slider input {
    width: 80px;
    height: 17px;
    opacity: 0;
    cursor: pointer;
}

.volume:hover+.volume-slider {
    visibility: visible;
}

.volume-slider {
    position: absolute;
    top: 3rem;
    right: 0.3rem;
    visibility: hidden;
    z-index: 2;
    padding: 6px 0;
}

.volume-slider:hover {
    visibility: visible;
}